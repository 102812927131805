/* eslint-disable react/jsx-no-target-blank */
import { FC, useEffect, useState } from 'react'
import { useAuth } from '../../../../../app/modules/auth'
import { KTSVG } from '../../../../helpers'
import { StatisticsWidget6 } from '../../../../partials/widgets'
import { AuthorsTab } from './AuthorsTab'
import { MenuTab } from './MenuTab'
import { NotificationsTab } from './NotificationsTab'
import { ProjectsTab } from './ProjectsTab'
import { SubscriptionsTab } from './SubscriptionsTab'
import { TasksTab } from './TasksTab'

type Props = {
  link: string
}

type CreditData = {
  color: string
  allowance: number
  usedCredits: number
  percentage: number
}

const SelectedTab: FC<Props> = ({ link }) => {
  switch (link) {
    case 'projects':
      return <ProjectsTab />
    case 'menu':
      return <MenuTab />
    case 'subscription':
      return <SubscriptionsTab />
    case 'tasks':
      return <TasksTab />
    case 'notifications':
      return <NotificationsTab />
    case 'authors':
      return <AuthorsTab />
    default:
      return <MenuTab />
  }
}

const TabsBase: FC<Props> = ({ link }) => {
  const { currentUser } = useAuth()
  const [creditData, setCreditData] = useState<CreditData>()

  useEffect(() => {
    if (currentUser) {
      const p = (currentUser.usedCredits / currentUser.allowance) * 100;
      setCreditData({
        color: p > 75 ? 'danger' : 'primary',
        allowance: currentUser.allowance,
        usedCredits: currentUser.usedCredits,
        percentage: p,
      })
    }
  }, [currentUser])

  return (
    <div className='d-flex h-100 flex-column'>
      {/* begin::Wrapper */}
      <div
        className='flex-column-fluid hover-scroll-y'
        data-kt-scroll='true'
        data-kt-scroll-activate='true'
        data-kt-scroll-height='auto'
        data-kt-scroll-wrappers='#kt_aside_wordspace'
        data-kt-scroll-dependencies='#kt_aside_secondary_footer'
        data-kt-scroll-offset='0px'
      >
        {/* begin::Tab content */}
        <div className='tab-content'>
          <div
            className='tab-pane fade active show'
            id={`kt_aside_nav_tab_${link}`}
            role='tabpanel'
          >
            <SelectedTab link={link} />
          </div>
        </div>
        {/* end::Tab content */}
      </div>
      {/* end::Wrapper */}
      {/* begin::Footer */}
      <div className='flex-column-auto pt-10' id='kt_aside_secondary_footer'>

        {creditData &&
          <div className={`card bg-light-${creditData.color}`}>
            {/* begin::Body */}
            <div className='card-body my-3'>
              <a href='#' className={`card-title fw-bold text-${creditData.color} fs-5 mb-3 d-block`}>
                Remaining Credits
              </a>

              <div className='py-1'>
                <span className='text-dark fs-1 fw-bold me-2'>{creditData.allowance -  creditData.usedCredits}</span>

                <span className='fw-semibold text-muted fs-7'>tokens</span>
              </div>

              <div className={`progress h-7px bg-${creditData.color} bg-opacity-50 mt-3`}>
                <div
                  className={`progress-bar bg-${creditData.color}`}
                  role='progressbar'
                  style={{ width: 100 - creditData.percentage + '%' }}
                />
              </div>
            </div>
          </div>
        }
        {/* <a
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
          target='_blank'
          className='btn btn-bg-light btn-color-gray-600 btn-flex btn-active-color-primary flex-center w-100'
          data-bs-toggle='tooltip'
          data-bs-custom-class='tooltip-dark'
          data-bs-trigger='hover'
          data-bs-offset='0,5'
          data-bs-dismiss-='click'
        >
          <span className='btn-label'>Docs & Components</span>
          <KTSVG
            path='/media/icons/duotune/general/gen005.svg'
            className='btn-icon svg-icon-4 ms-2'
          />
        </a> */}
      </div>
      {/* end::Footer */}
    </div>
  )
}

export { TabsBase }
