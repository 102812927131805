import { FC, useContext, useEffect, useState } from "react";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { checkoutProduct, getProduct, openPortal, ProductModel } from "../../modules/services/PurchaseService";
import ProductItem from "./ProductItem";
import { GlobalStateContext } from "../../AppState";
import { useAuth } from "../../modules/auth";

const adBreadCrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const PlanPage: FC = () => {
    const [isLoading, setLoading] = useState(false)

    const [mode, setMode] = useState('top-up');
    const { state, setState } = useContext(GlobalStateContext);
    const { products } = state;
    const { currentUser } = useAuth()


    useEffect(() => {
        if (currentUser) {
            console.log(currentUser)
        }
    }, [currentUser])




    const checkout = (id: string) => {
        setLoading(true)
        checkoutProduct(id)
    }

    const genProductList = () => {
        if (mode === 'subscription') {
            return products.filter(i => i.prices.type === 'recurring')
        } else {
            return products.filter(i => i.prices.type === 'one_time')
        }
    }

    return <>
        <PageTitle breadcrumbs={adBreadCrumbs}>Packages</PageTitle>
        <div className="card py-5">
            <div className="card-body">

                <div className="d-flex flex-column">

                    <div className="mb-13 text-center">
                        <h1 className="fs-2hx fw-bold mb-5">Choose Your Package</h1>
                        <div className="text-gray-400 fw-semibold fs-5">
                            Choose the package that suits your needs. You can add more credits at any time.
                        </div>

                    </div>
                    {/* <div className="nav-group nav-group-outline mx-auto mb-15" data-kt-buttons="true">
                        <button className={`btn btn-color-gray-400 btn-active btn-active-primary px-6 py-3 me-2` + (mode === 'subscription' ? ' active' : '')}
                            onClick={() => setMode('subscription')}>Subscription</button>
                        <button className={"btn btn-color-gray-400 btn-active btn-active-primary px-6 py-3" + (mode === 'top-up' ? ' active' : '')}
                            onClick={() => setMode('top-up')}>Top-Up</button>
                    </div> */}
                    <div className="row g-10">
                        {genProductList().map((product, index) =>
                            <div className="col-12 col-lg-6 col-xl-4" key={"Plan_" + index}>
                                <ProductItem product={product} isLoading={isLoading} onPlanSelect={() => checkout(product.prices.id)} />
                            </div>
                        )}

                    </div>
                </div>
            </div >
        </div >
    </>
}

export default PlanPage;