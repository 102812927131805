import axios from 'axios'
import { API_URL } from '../auth/core/_requests'

const GET_VEHICLE = `${API_URL}/get-vehicle`
const GENERATE = `${API_URL}/generate`
const GET_Ad = `${API_URL}/getAd`
const RATE_Ad = `${API_URL}/rateAd`


export interface VehicleInputModel {
    vrm: string
    keys: string
    serviceHistory: string
    mileage: number
}

export interface VehicleModel {
    status: boolean
    make: string
    model: string
    variant: string
    colour: string
    year: string
    fuel: string
    vrm?: string
    specs: any
}

export interface AdModel {
    adDetails: {
        uuid: string
        vrm: string
        keys: string
        serviceHistory: string
        mileage: number
        question: string
        text: string[]
        date: string
        specs?: any
        rating?: number[]
    }
    vehicleDetails: {
        make: string
        model: string
        variant: string
        colour: string
        year: string
        fuel: string
    }
}

export interface AdResponseModel {
    status: boolean
    uuid: string
    res: string[]
}


export async function getVehicleDetails<VehicleModel>(vrm: string) {
    const vehicle_data = await axios.post(GET_VEHICLE, {
        vrm,
    });

    if (vehicle_data && vehicle_data.status) {
        return vehicle_data.data;
    }
}

export async function generateAd<AdModel>(vehicle: VehicleInputModel, specs: any) {
    const ad_data = await axios.post(GENERATE, { ...vehicle, specs });

    if (ad_data && ad_data.status) {
        return ad_data.data;
    }
}

export async function getAd(uuid: string) {
    try {
        const ad_data = await axios.post<AdModel>(GET_Ad, {
            uuid
        });

        if (ad_data && ad_data.status == 200) {
            return ad_data.data;
        }
    } catch (e) {
        return null;
    }
}

export async function rateAd(uuid: string, index: number, rate: number) {
    try {
        const ad_data = await axios.post<boolean>(RATE_Ad, {
            uuid,
            i: index,
            rate
        });

        if (ad_data && ad_data.status == 200) {
            return ad_data.data;
        }
    } catch (e) {
        return null;
    }
}