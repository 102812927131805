import axios from 'axios'
import { API_URL } from '../auth/core/_requests'


const GET_Product = `${API_URL}/payment/getProduct`
const GET_Purchase = `${API_URL}/getPurchase`
const GET_Subscriptions = `${API_URL}/payment/getSubs`
const Init_Checkout = `${API_URL}/payment/initCheckout`
const Get_Portal_link = `${API_URL}/payment/getPortalLink`

export interface ProductModel {
    id: string;
    active: boolean;
    name: string;
    description: string;
    metadata: {
        allowance: string;
    };
    images: string[];
    prices: {
        id: string;
        type: string;
        unit_amount: number;
        currency: string;
        recurring: {
            aggregate_usage: null | string;
            interval: string;
            interval_count: number;
            trial_period_days: null | number;
            usage_type: string;
        };
    };
};

export interface SubscriptionModel {
    name: string;
    description: string;
    end_date : string;
    allowance: number;
};

export interface PurchaseModel {
    uuid: string;
    date: string;
    end_date: string;
    invoice: string;
    allowance: number;
    price: number;
    name: string;
    type: string;
    description: string;
}


export async function getProduct() {
    const products_data = await axios.get<ProductModel[]>(GET_Product);

    if (products_data) {
        return products_data.data;
    }
    return [];
}
export async function getSubscriptions() {
    const subs_data = await axios.get<SubscriptionModel[]>(GET_Subscriptions);

    if (subs_data) {
        return subs_data.data;
    }
    return [];
}

export async function getPurchase() {
    const purchase_data = await axios.get<PurchaseModel[]>(GET_Purchase);

    if (purchase_data) {
        return purchase_data.data;
    }
    return [];
}

export async function checkoutProduct(productID: string) {
    const checkout_data = await axios.post(Init_Checkout, {
        productID,
    });

    if (checkout_data) {
        window.location.href = checkout_data.data.url;
        return true;
    }
    return null;
}

export async function openPortal() {
    const portal_data = await axios.post(Get_Portal_link,{

    });

    if (portal_data) {
        window.location.href = portal_data.data.url;
        // console.log(portal_data.data.url);
        return true;
    }
    return null;
}

