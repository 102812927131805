import { FC } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import { ProductModel } from "../../modules/services/PurchaseService";

const ProductItem: FC<{
    product: ProductModel;
    onPlanSelect: () => void;
    isLoading?: boolean;
}> = ({
    product,
    onPlanSelect,
    isLoading
}) => {

        const formatPrice = () => {
            return product ? (product.prices.unit_amount / 100)
                .toLocaleString('en-GB', { style: 'currency', currency: product.prices.currency.toUpperCase() }) : '';
        }

        const genDisableItems = (text: string) => {
            return <div className="d-flex align-items-center">
                <span className="fw-semibold fs-6 text-gray-400 flex-grow-1">{text}</span>
                <span className="svg-icon svg-icon-1">
                    <KTSVG
                        path='/media/icons/duotune/general/gen040.svg'
                        className='svg-icon-1 me-1 svg-icon-gray-400'
                    />
                </span>
            </div>
        }

        const genEnabledItems = (text: string) => {
            return <div className="d-flex align-items-center mb-5">
                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">{text}</span>
                <span className="svg-icon svg-icon-1 svg-icon-success">
                    <KTSVG
                        path='/media/icons/duotune/general/gen043.svg'
                        className='svg-icon-1 me-1 svg-icon-success'
                    />
                </span>
            </div>
        }


        return <>

            <div className="d-flex h-100 align-items-center card shadow">
                <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
                    <div className="mb-7 text-center">
                        <div className="mb-7">
                            <img className="w-100px" style={{ objectFit: 'scale-down' }} src={product.images[0]} alt="" />
                        </div>
                        <h1 className="text-dark mb-5 fw-bolder">{product.name}</h1>
                        <div className="text-gray-400 fw-semibold mb-5"> {product.description} </div>
                        <div className="text-center">
                            {/* <span className="mb-2 text-primary">£</span> */}
                            <span className="fs-3x fw-bold text-primary">{formatPrice()}</span>
                            {product.prices.type === 'recurring' &&
                                <span className="fs-7 fw-semibold opacity-50">/
                                    <span data-kt-element="period">Month</span></span>
                            }
                        </div>
                    </div>
                    <div className="w-100 mb-10">
                        
                        {genEnabledItems(product.metadata.allowance + " Added Allowance")}

                    </div>
                    <button className="btn btn-primary" onClick={() => onPlanSelect()} disabled={isLoading}>
                        {isLoading ? 'Loading...' : 'Add'}
                    </button>
                </div>
            </div>


        </>
    }



export default ProductItem